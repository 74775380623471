import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import UsersTable from "@/components/admin/users/UsersTable.vue";
import CategoriesTabs from "@/components/admin/users/CategoriesTabs.vue";
import CategoriesActions from "@/components/admin/users/CategoriesActions.vue";
import HeaderActions from "@/components/admin/users/HeaderActions.vue";
let UsersPage = class UsersPage extends Vue {
    constructor() {
        super(...arguments);
        this.clearSelected = false;
        this.category = "active";
        this.selectedUsers = [];
    }
    setCategory(category) {
        this.category = category;
    }
    setSelectedUsers(users) {
        this.selectedUsers = users;
    }
};
UsersPage = __decorate([
    Component({
        components: {
            UsersTable,
            HeaderActions,
            CategoriesTabs,
            CategoriesActions,
        },
    })
], UsersPage);
export default UsersPage;
