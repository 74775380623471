import mapValues from "lodash.mapvalues";
//@ts-ignore
import sortAny from "sort-any";
import { updatedDiff } from "deep-object-diff";
export const sortDeep = (object) => {
    if (object instanceof Map) {
        return sortAny([...object]);
    }
    if (!Array.isArray(object)) {
        if (typeof object !== "object" || object === null || object instanceof Date) {
            return object;
        }
        return mapValues(object, sortDeep);
    }
    return sortAny(object.map(sortDeep));
};
export default (v1, v2) => updatedDiff(sortDeep(v1), sortDeep(v2));
