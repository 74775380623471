import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import ApproveDialog from "../../shared/Approve.vue";
import UserBasicInfo from "../dialogs/User/UserBasicInfo.vue";
let HeaderActions = class HeaderActions extends Vue {
    constructor() {
        super(...arguments);
        this.addMember = {
            dialog: false,
        };
        this.buttons = [
            {
                text: `Добавить члена РФСЛ`,
                icon: "mdi-account-plus",
                action: () => {
                    this.addMember.dialog = true;
                    //console.log(`addMemberDialog`, this.addMemberDialog)
                },
                css: "blue",
                showOnlyForSuperAdmin: false,
            },
            {
                text: `Категорию в Excel`,
                icon: "mdi-file-excel-outline",
                action: () => this.excelExport("byCategory"),
                css: "green",
                showOnlyForSuperAdmin: false,
            },
            {
                text: `Все категории в Excel`,
                icon: "mdi-file-excel-outline",
                action: () => this.excelExport("all"),
                css: "green",
                showOnlyForSuperAdmin: false,
            },
        ];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    async excelExport(type = "all") {
        let data = {
            category: this.category,
            regionId: this.admin.regionId,
        };
        if (type === "all") {
            data.category = "any";
        }
        if (this.isSuperAdmin) {
            delete data.regionId;
        }
        await this.$store.dispatch(UsersModule.types.actions.EXCEL_EXPORT, data);
        this.$notify({
            type: "success",
            title: "Экспорт прошел успешно!",
        });
    }
};
__decorate([
    Prop({ type: String })
], HeaderActions.prototype, "category", void 0);
HeaderActions = __decorate([
    Component({
        components: {
            ApproveDialog,
            UserBasicInfo
        },
    })
], HeaderActions);
export default HeaderActions;
