import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment";
import AdminModule from "@/store/module/admin/AdminModule";
let UserProfileViewDialog = class UserProfileViewDialog extends Vue {
    constructor() {
        super(...arguments);
        this.updateProfile = {
            dialog: false,
        };
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get user() {
        return this.$user;
    }
    get profile() {
        return this.$user.profile;
    }
    get view() {
        if (!this.profile)
            return [];
        const data = [];
        const getDate = (value) => {
            if (!value)
                return "Не указана";
            return moment(value).format("YYYY-MM-DD");
        };
        data.push({
            title: "",
            items: [
                {
                    title: "Год, с которого состоит в сборной",
                    value: this.profile.inNationalTeamSinceYear || "Не указан",
                },
            ],
        }, {
            title: "Паспортные данные",
            items: [
                { title: "Серия", value: this.profile.passport.serial || "Не указана" },
                { title: "Номер", value: this.profile.passport.number || "Не указан" },
                { title: "Код подразделения", value: this.profile.passport.authorityCode || "Не указан" },
                { title: "Дата выдачи", value: getDate(this.profile.passport.issueDate) },
                { title: "Кем выдан", value: this.profile.passport.authority || "Не указано" },
                { title: "Место рождения", value: this.profile.passport.birthPlace || "Не указано" },
            ],
        });
        const employment = {
            title: "Образование / Занятость",
            items: [{ title: "Образование", value: this.profile.employment.education }],
        };
        if (this.profile.employment.educationPlace) {
            employment.items.push({
                title: "Место учебы",
                value: this.profile.employment.educationPlace || "Не указано",
            });
        }
        else if (this.profile.employment.workPlace) {
            employment.items.push({
                title: "Место работы",
                value: this.profile.employment.workPlace || "Не указано",
            });
        }
        data.push(employment, {
            title: "Место проживания",
            items: [
                { title: "Адрес", value: this.profile.living.address || "Не указан" },
                { title: "Индекс", value: this.profile.living.index || "Не указан" },
            ],
        }, {
            title: "Социальные службы / МЕДСТРАХ",
            items: [
                { title: "Номер страхового полиса ОМС", value: this.profile.social.medicialInsurance || "Не указан" },
                { title: "ИНН", value: this.profile.social.TIN || "Не указан" },
                { title: "СНИЛС", value: this.profile.social.INIPA || "Не указан" },
            ],
        }, {
            title: "Экипировка",
            items: [
                { title: "Размер одежды", value: this.profile.equip.size || "Не указано" },
                { title: "Рост", value: this.profile.equip.height || "Не указан" },
                { title: "Размер обуви", value: this.profile.equip.shoeSize || "Не указан" },
            ],
        });
        const aboard = {
            title: "Выезды за рубеж",
            items: [{ title: "Заграничный паспорт", value: this.profile.aboard.hasAboardPassport ? "Есть" : "Нет" }],
        };
        if (this.profile.aboard.hasAboardPassport) {
            aboard.items.push({ title: "Фамилия", value: this.profile.aboard.surname || "Не указана" }, { title: "Имя", value: this.profile.aboard.name || "Не указано" }, { title: "Отчество", value: this.profile.aboard.surname || "Не указана" }, { title: "Номер", value: this.profile.aboard.number || "Не указан" }, { title: "Кем выдан", value: this.profile.aboard.authority || "Не указано" }, { title: "Дата выдачи", value: getDate(this.profile.aboard.issueDate) }, { title: "Дата истечения срока", value: getDate(this.profile.aboard.expirationDate) });
        }
        aboard.items.push({ title: "Посещенные страны", value: this.profile.aboard.visitedCountries.join(", ") }, { title: "Есть виза в США", value: this.profile.aboard.hasUsaVisa ? "Да" : "Нет" }, { title: "Сдавалась биометрия", value: this.profile.aboard.hasBiometrics ? "Да" : "Нет" });
        if (this.profile.aboard.hasBiometrics) {
            aboard.items.push({ title: "Дата сдачи биометрии", value: getDate(this.profile.aboard.biometricsIssueDate) });
        }
        data.push(aboard, {
            title: "Визовые анкеты",
            items: [
                { title: "Ф.И.О матери", value: this.profile.visaProfile.motherSNP || "Не указано" },
                { title: "День рождения матери", value: getDate(this.profile.visaProfile.motherBirthdate) },
                { title: "Ф.И.О отца", value: this.profile.visaProfile.fatherSNP || "Не указано" },
                { title: "День рождения отца", value: getDate(this.profile.visaProfile.fatherBirthdate) },
            ],
        });
        const familyStatus = {
            title: "Семейное положение",
            items: [{ title: "Семейное положение", value: this.profile.familyStatus.partnershipType }],
        };
        if (this.profile.familyStatus.partnershipType === "Женат/Замужем") {
            familyStatus.items.push({
                title: "Ф.И.О супруга/супруги",
                value: this.profile.familyStatus.partnerSNP || "Не указано",
            });
            if (this.user.gender === "male") {
                familyStatus.items.push({
                    title: "Девичья фамилия супруги",
                    value: this.profile.familyStatus.partnerMaidenName || "Не указана",
                });
            }
            familyStatus.items.push({
                title: "Гражданство супруга/супруги",
                value: this.profile.familyStatus.partnerCitizenship || "Не указано",
            }, {
                title: "День рождения супруга/супруги",
                value: getDate(this.profile.familyStatus.partnerBirthdate),
            });
        }
        data.push(familyStatus);
        if (this.profile.sportsman && this.profile.sportsman.length > 0) {
            let n = 1;
            for (const sportsman of this.profile.sportsman) {
                data.push({
                    title: `Данные спортсмена №${n}`,
                    items: [
                        { title: "Группа дисциплин", value: sportsman.disciplineGroup || "Не указана" },
                        { title: "Дисциплина", value: sportsman.discipline || "Не указана" },
                        { title: "Год начала занятий", value: sportsman.startYear || "Не указан" },
                        { title: "Ф.И.О первого тренера", value: sportsman.firstCoachSNP || "Не указано" },
                        { title: "Ф.И.О личного тренера", value: sportsman.personalCoachSNP || "Не указано" },
                    ],
                });
                n += 1;
            }
        }
        if (this.profile.coach) {
            data.push({
                title: "Данные тренера",
                items: [{ title: "Тренерская категория (звание)", value: this.profile.coach.category || "Не указана" }],
            });
        }
        if (this.profile.judge) {
            data.push({
                title: "Данные судьи",
                items: [
                    {
                        title: "Категория спортивного судьи",
                        value: this.profile.judge.category || "Не указана",
                    },
                    {
                        title: "№ приказа",
                        value: this.profile.judge.order || "Не указан",
                    },
                    {
                        title: "Дата приказа",
                        value: this.profile.judge.orderDate || "Не указана",
                    },
                ],
            });
        }
        return data;
    }
    async mounted() { }
};
__decorate([
    Prop({ default: false, type: Boolean })
], UserProfileViewDialog.prototype, "value", void 0);
__decorate([
    Prop({ type: Object, required: true })
], UserProfileViewDialog.prototype, "$user", void 0);
UserProfileViewDialog = __decorate([
    Component({
        components: {},
    })
], UserProfileViewDialog);
export default UserProfileViewDialog;
