import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment-timezone";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import { mask } from "vue-the-mask";
import Validation from "@/helpers/Validation";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import HandbookModule from "@/store/module/shared/HandbookModule";
import GroupModule from "@/store/module/shared/GroupModule";
import { PhoneMask } from "@/helpers/PhoneMask";
import UserModule from "@/store/module/user/UserModule";
import ApproveDialog from "../../../shared/Approve.vue";
let UserBasicInfo = class UserBasicInfo extends Vue {
    constructor() {
        super(...arguments);
        this.pickedRegion = null;
        this.approve = {
            add: false,
            update: false,
        };
        this.name = {
            ref: "name",
            type: "input",
            label: "Имя",
            value: "",
            placeholder: "Роман",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.surname = {
            ref: "surname",
            type: "input",
            label: "Фамилия",
            value: "",
            placeholder: "Марков",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.patronymic = {
            ref: "patronymic",
            type: "input",
            label: "Отчество",
            value: "",
            placeholder: "Алексеевич",
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.birthdate = {
            ref: "birthdate",
            type: "datepicker",
            label: "Дата рождения",
            value: "",
            isOpened: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.gender = {
            ref: "gender",
            type: "select",
            label: "Пол",
            value: "male",
            items: () => [
                { label: "Мужской", value: "male" },
                { label: "Женский", value: "female" },
            ],
            isMultiple: false,
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.avatar = {
            ref: "avatar",
            type: "fileupload",
            icon: "mdi-camera",
            label: "",
            placeholder: "Загрузите фото в формате JPEG, PNG, BMP размером 35х45 мм 300 dpi",
            value: "",
            accept: "image/*",
            onChange: async (file) => {
                if (file === null)
                    return;
                const fd = new FormData();
                fd.append("avatar", file);
                const response = await this.$store.dispatch(UsersModule.types.actions.UPLOAD_AVATAR, { fd });
                this.avatar.value = response.filename;
            },
            rules: [Validation.required],
            showOnlyForSuperAuth: false,
            show: true,
        };
        this.phone = {
            ref: "phone",
            type: "phone",
            label: "Телефон",
            value: "",
            placeholder: PhoneMask.getPlaceholderByCountry("ru"),
            mask: PhoneMask.getMaskByPlaceholder("", PhoneMask.getPlaceholderByCountry("ru")),
            showOnlyForSuperAuth: false,
            rules: [Validation.required],
            show: true,
        };
        this.email = {
            ref: "email",
            type: "input",
            label: "E-mail",
            value: "",
            showOnlyForSuperAuth: false,
            rules: [Validation.required, Validation.email],
            show: true,
        };
        this.region = {
            ref: "region",
            type: "select",
            label: "Регион",
            value: "",
            items: () => this.regions,
            onInput: (id) => {
                this.pickedRegion = id;
                this.city.disabled = false;
            },
            isMultiple: false,
            noData: "Список доступных регионов пуст",
            showOnlyForSuperAuth: true,
            disabled: false,
            rules: [],
        };
        this.city = {
            ref: "city",
            type: "select",
            label: "Город",
            value: "",
            items: () => this.citiesByRegion,
            onInput: () => { },
            isMultiple: false,
            noData: "Список городов для выбранного региона пуст",
            showOnlyForSuperAuth: false,
            disabled: true,
            rules: [],
        };
        this.entryDate = {
            ref: "entryDate",
            type: "datepicker",
            label: "Дата вступления",
            value: moment().tz("Europe/Moscow").format("YYYY-MM-DD"),
            isOpened: false,
            showOnlyForSuperAuth: true,
            rules: [Validation.required],
        };
        this.expiryDate = {
            ref: "expiryDate",
            type: "datepicker",
            label: "Срок действия",
            value: moment(`${moment().year()}-12-31`).tz("Europe/Moscow").format("YYYY-MM-DD"),
            isOpened: false,
            showOnlyForSuperAuth: true,
            rules: [Validation.required],
        };
        this.sportsCategory = {
            ref: "sportsCategory",
            type: "select",
            label: "Спортивный разряд",
            value: "",
            items: () => this.sportsCategories,
            onInput: () => { },
            isMultiple: false,
            noData: "",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            show: true,
        };
        this.groupsPicker = {
            ref: "sportsmanGroup",
            type: "select",
            label: "Сообщество",
            value: [],
            items: () => this.groups,
            onInput: () => {
                console.log(this.groupsPicker.value);
            },
            isMultiple: true,
            noData: "Список сообществ пуст",
            showOnlyForSuperAuth: false,
            disabled: false,
            rules: [],
            returnObject: false,
            itemText: "shortName",
            itemValue: "id",
            show: true,
        };
        // commentary: IDefaultInput = {
        //   ref: "commentary",
        //   type: "input",
        //   label: "Комментарий",
        //   value: "",
        //   placeholder: "",
        //   showOnlyForSuperAuth: false,
        //   show: this.isUser,
        // }
        this.uploadedDocuments = [];
        this.documentsUpload = {
            ref: "docs",
            type: "fileupload",
            placeholder: "",
            value: [],
            accept: "image/*,application/*",
            onChange: async (files) => {
                if (files === null || files.length === 0) {
                    this.uploadedDocuments.splice(0, this.uploadedDocuments.length);
                    return;
                }
                let arrayOfFiles = [];
                if (Array.isArray(files) === false) {
                    arrayOfFiles = [files];
                }
                else {
                    arrayOfFiles = files;
                }
                console.log(arrayOfFiles);
                for (let file of arrayOfFiles) {
                    this.uploadedDocuments.push(file);
                }
            },
            rules: [],
            showOnlyForSuperAuth: false,
        };
        this.baseFields = [
            this.surname,
            this.name,
            this.patronymic,
            this.birthdate,
            this.gender,
            this.sportsCategory,
            this.groupsPicker,
        ];
        this.contactsField = [this.phone, this.email];
        this.addressFields = [this.region, this.city];
        this.timesFields = [this.entryDate, this.expiryDate];
        this.phoneCountrySelect = {
            defaultCountry: "ru",
            dialCode: "+7",
            onlyCountries: ["ru", "az", "am", "by", "kz", "md", "tj", "uz", "ua"],
            dynamicPlaceholder: true,
        };
    }
    get showDialog() {
        return this.value;
    }
    set showDialog(value) {
        this.$emit("input", value);
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get isRegionalAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_REGIONAL_ADMIN];
    }
    get title() {
        if (this.isUpdate)
            return "Редактирование данных члена РФСЛ";
        return "Регистрация нового члена РФСЛ";
    }
    get updateApproveText() {
        return {
            title: "Обновить данные члена РФСЛ?",
            okText: "Обновить",
        };
    }
    get citiesByRegion() {
        if (this.pickedRegion === null)
            return [];
        return this.$store.getters[HandbookModule.types.getters.CITIES]
            .map((item) => (item.regionId === this.pickedRegion ? item : undefined))
            .filter((item) => item !== undefined);
    }
    get regions() {
        return this.$store.getters[HandbookModule.types.getters.REGIONS];
    }
    get sportsCategories() {
        const items = this.$store.getters[HandbookModule.types.getters.SPORTS_CATEGORY].map((item) => ({
            label: item.category,
            value: item.id,
        }));
        items.unshift({ label: "", value: 0 });
        return items;
    }
    get groups() {
        const groups = this.$store.getters[GroupModule.types.getters.GROUPS];
        const items = groups.filter((group) => group.isAllRussian ||
            (!this.isSuperAdmin && group.contacts?.address?.regionId === this.admin?.regionId) ||
            this.isSuperAdmin);
        //items.unshift({ shortName: "", id: 0 } as Group.Group)
        return items;
    }
    get defaultAvatar() {
        return `${IMAGE_SERVER_URL}/public/user-placeholder-${this.user.gender}.jpg`;
    }
    get uploadedAvatar() {
        return `${IMAGE_SERVER_URL}/public/${this.avatar.value}`;
    }
    async addDocuments() {
        document.getElementById("documentsUpload").click();
    }
    onPhoneCountryChange(val) {
        this.phoneCountrySelect.dialCode = val.dialCode;
        this.phone.placeholder = PhoneMask.getPlaceholderByCountry(val.iso2.toLowerCase());
        this.phone.mask = PhoneMask.getMaskByPlaceholder(``, this.phone.placeholder);
        this.phone.value.replace(/\+[0-9]+/, "");
    }
    async hasByEmailOrPhone() {
        const response = await this.$store.dispatch("User/hasByEmailOrPhone", {
            email: this.email.value,
            phone: `+${this.phoneCountrySelect.dialCode} ${this.phone.value}`,
        });
        if (response.status === 409) {
            return false;
        }
        else if (response.status === 200) {
            this.$notify({
                type: "error",
                duration: 3000,
                title: "Уже есть пользователь с такой комбинацией email и телефона",
            });
            return true;
        }
        else {
            for (let error of response.data) {
                this.$notify({
                    type: "error",
                    duration: 3000,
                    title: "Ошибка проверки наличия дублирования пользователя",
                    text: error.msg,
                });
            }
            return true;
        }
    }
    async hasByNSPB() {
        const response = await this.$store.dispatch(UserModule.types.actions.IS_USER_EXISTED_BY, {
            name: this.name.value,
            surname: this.surname.value,
            patronymic: this.patronymic.value,
            birthdate: this.birthdate.value,
        });
        if (response.status) {
            this.$notify({
                type: "error",
                duration: 3000,
                title: "Уже есть пользователь с такой комбинацией Ф.И.О. и даты рождения",
            });
            return true;
        }
        return false;
    }
    setTestFormData() {
        this.name.value = "Сергей";
        this.surname.value = "Павлович";
        this.patronymic.value = "Александрович";
        this.birthdate.value = moment().tz("Europe/Moscow").format("YYYY-MM-DD");
        this.gender.value = "male";
        this.avatar.value = "";
        this.phone.value = "(931) 231-23-23";
        this.email.value = "wow-aka.moy@yandex.ru";
        this.region.value = 85;
        this.region.onInput(85);
        //this.city.value = 795
    }
    cleanFormData() {
        this.avatar.value = "";
        this.name.value = "";
        this.surname.value = "";
        this.patronymic.value = "";
        this.birthdate.value = "";
        this.gender.value = null;
        this.avatar.value = "";
        this.phone.value = "";
        this.email.value = "";
        this.region.value = "";
        this.city.value = "";
        this.sportsCategory.value = "";
        this.groupsPicker.value = "";
        this.expiryDate.value = moment(`${moment().year()}-12-31`).tz("Europe/Moscow").format("YYYY-MM-DD");
        this.entryDate.value = moment().tz("Europe/Moscow").format("YYYY-MM-DD");
        if (this.admin !== null && this.isSuperAdmin === false) {
            this.region.onInput(this.admin.regionId);
            this.region.value = this.admin.regionId;
            this.region.disabled = true;
        }
    }
    async add() {
        let form = this.baseFields.concat(this.contactsField, this.addressFields);
        if (this.isSuperAdmin) {
            form = form.concat(this.timesFields);
        }
        form.push(this.avatar);
        let hasError = Validation.validateWithView(form, this.$refs);
        if (!hasError) {
            const user = {
                name: this.name.value,
                surname: this.surname.value,
                patronymic: this.patronymic.value,
                birthdate: this.birthdate.value,
                gender: this.gender.value,
                avatar: this.avatar.value === "" ? null : this.avatar.value,
                phone: `+${this.phoneCountrySelect.dialCode} ${this.phone.value}`,
                email: this.email.value,
                address: {
                    regionId: parseInt(this.region.value),
                    cityId: parseInt(this.city.value),
                },
                entryDate: this.entryDate.value,
                expiryDate: this.expiryDate.value,
                isStopped: false,
                isHonorary: false,
                isPremoderated: true,
                isNationalTeamMember: false,
            };
            if (parseInt(this.sportsCategory.value) === 0) {
                user.sportsCategory = null;
            }
            else if (this.sportsCategory.value !== "") {
                user.sportsCategory = {
                    sportsCategoryId: parseInt(this.sportsCategory.value),
                };
            }
            const hasByNSPB = await this.hasByNSPB();
            if (hasByNSPB)
                return;
            const response = await this.$store.dispatch(UsersModule.types.actions.ADD_USER, { user });
            this.$notify({
                type: "success",
                title: "Член РФСЛ успешно добавлен в систему и отправлен на премодерацию",
            });
            this.cleanFormData();
            if (this.groupsPicker.value.length > 0) {
                for (let groupId of this.groupsPicker.value) {
                    await this.$store.dispatch(GroupModule.types.actions.ADD_PARTICIPANTS, {
                        groupId,
                        participants: [
                            {
                                groupId,
                                participantId: response.id,
                                ampluaId: 1,
                            },
                        ],
                    });
                }
            }
        }
    }
    async update() {
        let form = this.baseFields.concat(this.contactsField, this.addressFields);
        if (this.isSuperAdmin) {
            form = form.concat(this.timesFields);
        }
        form.push(this.avatar);
        let hasError = Validation.validateWithView(form, this.$refs);
        if (hasError)
            return;
        if (this.groupsPicker.value.length > 0) {
            const memberTable = this.getParticipationTable();
            const pickerTable = {};
            this.groupsPicker.value.map((id) => (pickerTable[id] = true));
            for (let group of this.groups) {
                const isMember = Object.prototype.hasOwnProperty.call(memberTable, group.id);
                const isPicked = Object.prototype.hasOwnProperty.call(pickerTable, group.id);
                const participant = group.participants.find((p) => p.participantId === this.user.id);
                if (isMember && !isPicked) {
                    await this.$store.dispatch(GroupModule.types.actions.DELETE_PARTICIPANTS, {
                        groupId: group.id,
                        participantsIds: [participant.id],
                    });
                }
                else if (!isMember && isPicked) {
                    await this.$store.dispatch(GroupModule.types.actions.ADD_PARTICIPANTS, {
                        groupId: group.id,
                        participants: [
                            {
                                groupId: parseInt(String(group.id)),
                                participantId: this.user.id,
                                ampluaId: 1,
                            },
                        ],
                    });
                }
            }
        }
        const user = {
            id: this.user.id,
            name: this.name.value,
            surname: this.surname.value,
            patronymic: this.patronymic.value,
            birthdate: this.birthdate.value,
            gender: this.gender.value,
            avatar: this.avatar.value,
            phone: `+${this.phoneCountrySelect.dialCode} ${this.phone.value}`,
            email: this.email.value,
            entryDate: this.entryDate.value,
            expiryDate: this.expiryDate.value,
            isStopped: this.user.isStopped,
            isHonorary: this.user.isHonorary,
            isPremoderated: this.user.isPremoderated,
            isNationalTeamMember: this.user.isNationalTeamMember,
            groupsParticipant: this.user.groupsParticipant,
            eventsParticipant: this.user.eventsParticipant,
        };
        if (parseInt(this.sportsCategory.value) === 0) {
            user.sportsCategory = null;
        }
        else if (this.user.sportsCategory !== null &&
            parseInt(this.sportsCategory.value) !== parseInt(String(this.user.sportsCategory.sportsCategoryId))) {
            user.sportsCategory = {
                ...this.user.sportsCategory,
                sportsCategoryId: parseInt(this.sportsCategory.value),
            };
        }
        else if (this.sportsCategory.value !== "" && this.user.sportsCategory === null) {
            user.sportsCategory = {
                sportsCategoryId: parseInt(this.sportsCategory.value),
            };
        }
        const noAddress = this.user.addressId === null && this.region.value !== "" && this.city.value !== "";
        const equalAddress = this.user.addressId !== null &&
            parseInt(this.region.value) === this.user.address.regionId &&
            parseInt(this.city.value) === this.user.address.cityId;
        if (noAddress || !equalAddress) {
            const response = await this.$store.dispatch(HandbookModule.types.actions.ADD_ADDRESS, {
                address: {
                    regionId: parseInt(this.region.value),
                    cityId: parseInt(this.city.value),
                },
            });
            user.addressId = response.id;
        }
        await this.$store.dispatch(UsersModule.types.actions.UPDATE_USER, { id: this.user.id, user });
        this.$notify({
            type: "success",
            title: "Данные Члена РФСЛ успешно обновлены",
        });
    }
    // async updateRequest() {
    //   const form = [this.surname, this.name, this.patronymic, this.birthdate, this.gender]
    //   let hasError = Validation.validateWithView(form, this.$refs)
    //   if (hasError) return
    //   let docs: Handbook.GNRLDocument[] = []
    //   if (this.uploadedDocuments.length > 0) {
    //     const fd = new FormData()
    //     for (let doc of this.uploadedDocuments) {
    //       fd.append("docs", doc)
    //     }
    //     const response = await this.$store.dispatch("Handbook/uploadDocuments", fd)
    //     if (response.status !== 200) {
    //       for (let error of response.data) {
    //         this.$notify({
    //           type: "error",
    //           title: "Ошибка загрузки документов на сервер",
    //           text: error.msg,
    //         })
    //       }
    //       return
    //     }
    //     docs = response.data as Handbook.GNRLDocument[]
    //   }
    //   const user: Partial<User.UserUpdateRequest> = {
    //     userId: this.user.id,
    //     name: this.name.value.trim(),
    //     surname: this.surname.value.trim(),
    //     patronymic: this.patronymic.value.trim(),
    //     birthdate: this.birthdate.value.trim(),
    //     gender: this.gender.value,
    //     avatar: this.avatar.value,
    //     phone: `+${this.phoneCountrySelect.dialCode} ${this.phone.value}`.trim(),
    //     commentary: this.commentary.value === "" ? null : this.commentary.value.trim(),
    //   }
    //   const response = await this.$store.dispatch("User/updateRequest", {
    //     user,
    //     docs,
    //   })
    //   if (response.status !== 200) {
    //     for (let error of response.data) {
    //       this.$notify({
    //         type: "error",
    //         title: "Ошибка отправки заявки",
    //         text: error.msg,
    //       })
    //     }
    //     return
    //   }
    //   this.$notify({
    //     title: "Заявка на обновление данных успешно отправлена!",
    //     duration: 3000,
    //     type: "success",
    //   })
    //   this.value = false
    // }
    getParticipationTable() {
        const table = {};
        this.user.groupsParticipant.map((p) => (table[p.groupId] = p));
        return table;
    }
    checkGroupsWithMembership() {
        const table = this.getParticipationTable();
        this.groups.map((g) => {
            if (Object.prototype.hasOwnProperty.call(table, g.id)) {
                ;
                this.groupsPicker.value.push(g.id);
            }
        });
    }
    async updateRoute() {
        // if (this.isUser) return await this.updateRequest()
        return this.update();
    }
    async mounted() {
        await this.$store.dispatch(AdminModule.types.actions.WAIT_FOR_ADMIN_LOADING);
        await this.$store.dispatch(HandbookModule.types.actions.WAIT_FOR_HANDBOOK_LOAD);
        if (this.isUpdate) {
            this.name.value = this.user.name;
            this.surname.value = this.user.surname;
            this.patronymic.value = this.user.patronymic;
            this.birthdate.value = moment(this.user.birthdate).format("YYYY-MM-DD");
            this.gender.value = this.user.gender;
            this.avatar.value = this.user.avatar;
            this.phone.value = this.user.phone ? this.user.phone.replace(/\+[0-9]+/, "") : "";
            this.email.value = this.user.email;
            this.region.value = this.user.address.regionId;
            this.city.value = this.user.address.cityId;
            this.entryDate.value = moment(this.user.entryDate).format("YYYY-MM-DD");
            this.expiryDate.value = moment(this.user.expiryDate).format("YYYY-MM-DD");
            this.region.value = this.user.address.regionId;
            this.region.onInput(this.user.address.regionId);
            this.city.disabled = false;
            this.sportsCategory.value = this.user?.sportsCategory?.sportsCategory?.id;
            this.checkGroupsWithMembership();
        }
        // if (!this.isUpdate) {
        //   this.setTestFormData()
        // }
        if (this.groups.length === 0) {
            await this.$store.dispatch(GroupModule.types.actions.GET_GROUPS);
        }
        if (this.admin !== null && this.isSuperAdmin === false) {
            this.region.onInput(this.admin.regionId);
            this.region.value = this.admin.regionId;
            this.region.disabled = true;
        }
        if (location.origin.match(/localhost/)) {
            // @ts-ignore
            window.setTestFormData = this.setTestFormData;
            // @ts-ignore
            window.cleanFormData = this.cleanFormData;
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], UserBasicInfo.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean })
], UserBasicInfo.prototype, "isUpdate", void 0);
__decorate([
    Prop({ type: Object })
], UserBasicInfo.prototype, "user", void 0);
UserBasicInfo = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
        directives: {
            mask,
        },
    })
], UserBasicInfo);
export default UserBasicInfo;
