import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import UsersModule from "@/store/module/admin/UsersModule";
let CategoriesTabs = class CategoriesTabs extends Vue {
    constructor() {
        super(...arguments);
        this.activeTab = 0;
        this.defaultTabs = [
            { title: "Активные", type: "active" },
            {
                title: "Приостановленные",
                type: "suspended",
            },
            { title: "Юные лучники", type: "junior" },
            { title: "Не активные", type: "inactive" },
        ];
        this.superAdminTabs = [
            {
                title: "Почетные",
                type: "honorary",
            },
            {
                title: "Премодерация",
                type: "premoderated",
            },
            {
                title: "Заявки ЛК",
                type: "lk",
            },
            {
                title: "Сборная",
                type: "nationalTeam",
            },
        ];
    }
    get isSuperAdmin() {
        return this.$store.getters["Admin/isSuperAdmin"];
    }
    get users() {
        return this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES];
    }
    get tabs() {
        if (!this.isSuperAdmin)
            return this.defaultTabs;
        return this.defaultTabs.concat(this.superAdminTabs);
    }
    getCategoryUsersCount(category) {
        return this.users[category].length;
    }
    changeCategory(category) {
        return category;
    }
};
__decorate([
    Emit()
], CategoriesTabs.prototype, "changeCategory", null);
CategoriesTabs = __decorate([
    Component
], CategoriesTabs);
export default CategoriesTabs;
