import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import UsersModule from "@/store/module/admin/UsersModule";
import ApproveDialog from "@/components/shared/Approve.vue";
import UserUpdateRequestView from "./UserUpdateRequestView.vue";
import UserUpdateRequestDTO from "@/shared/application/UserUpdateRequestDTO";
let UserUpdateRequestCard = class UserUpdateRequestCard extends Vue {
    constructor() {
        super(...arguments);
        this.dialog = {
            update: false,
            reject: false,
        };
        this.showRejectInput = false;
        this.rejectReason = {
            ref: "rejectReason",
            type: "input",
            label: "Укажите причину отклонения заявки",
            value: "",
            placeholder: "",
            showOnlyForSuperAuth: false,
            show: true,
        };
    }
    get updateRequest() {
        return this.user.updateRequest?.data || null;
    }
    get userDto() {
        if (!this.user)
            return null;
        return UserUpdateRequestDTO.createFromUser(this.user);
    }
    get updateRequestDiff() {
        if (!this.updateRequest)
            return {};
        return this.userDto.getDifferenceTo(new UserUpdateRequestDTO(this.updateRequest.request, this.updateRequest.commentary, this.updateRequest.documents));
    }
    get currentDataByDiff() {
        return UserUpdateRequestDTO.fillDifferenceByUser(this.updateRequestDiff, this.user);
    }
    get commentary() {
        return !this.user?.updateRequest?.data.commentary ? "Не указан" : this.user.updateRequest.data.commentary;
    }
    get documentsTable() {
        return {
            selected: [],
            headers: [
                {
                    text: "Документы",
                    sortable: false,
                    value: "src",
                },
            ],
            items: this.user.updateRequest.data.documents,
            search: "",
            loading: false,
            pagesCount: 0,
            itemsPerPage: 10,
            page: 1,
            sortBy: "name",
            showExpand: false,
            singleExpand: false,
        };
    }
    getDocumentLink(src) {
        return `${IMAGE_SERVER_URL}/documents/${src}`;
    }
    getDocumentName(src) {
        return src.replace(/[0-9]{13}\_/, "");
    }
    async reject() {
        await this.$store.dispatch(UsersModule.types.actions.REJECT_USER_UPDATE_REQUEST, {
            userId: this.user.id,
            requestId: this.user.updateRequest.id,
            rejectReason: this.rejectReason.value ? this.rejectReason.value.trim() : null,
        });
        this.$notify({
            type: "success",
            title: "Заявка успешно отклонена",
            duration: 3000,
        });
    }
    async update() {
        await this.$store.dispatch(UsersModule.types.actions.APPROVE_USER_UPDATE_REQUEST, {
            userId: this.user.id,
            requestId: this.user.updateRequest.id,
        });
        this.$notify({
            type: "success",
            title: "Заявка успешно одобрена!",
            duration: 3000,
        });
    }
};
__decorate([
    Prop({ type: Object, required: true })
], UserUpdateRequestCard.prototype, "user", void 0);
UserUpdateRequestCard = __decorate([
    Component({
        components: {
            ApproveDialog,
            UserUpdateRequestView,
        },
    })
], UserUpdateRequestCard);
export default UserUpdateRequestCard;
