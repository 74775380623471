import NullablePrimitive from "../domain/value-object/NullablePrimitive";
import deepEqualInAnyOrder, { sortDeep } from "@/helpers/deepEqualInAnyOrder";
import moment from "moment";
export default class UserUpdateRequestDTO {
    constructor(request, commentary, documents) {
        this.request = request;
        this.commentary = commentary;
        this.documents = documents;
    }
    sortObj(target, order) {
        return Object.keys(target)
            .sort((a, b) => order.indexOf(a) - order.indexOf(b))
            .reduce((obj, key) => {
            obj[key] = target[key];
            return obj;
        }, {});
    }
    getDifferenceTo(dto) {
        const diff = deepEqualInAnyOrder(this.request, dto.request);
        const empty = UserUpdateRequestDTO.emptyRaw();
        const baseOrder = Object.keys(UserUpdateRequestDTO.emptyRaw());
        for (const field in diff) {
            const fieldTyped = field;
            const innerOrder = Object.keys(empty[fieldTyped]);
            diff[fieldTyped] = this.sortObj(diff[fieldTyped], innerOrder);
        }
        if (this.request.sportsman) {
            diff.sportsman = [];
            const sportsmanKeysOrder = [
                "disciplineGroup",
                "discipline",
                "firstCoachId",
                "firstCoachSNP",
                "personalCoachId",
                "personalCoachSNP",
                "startYear",
            ];
            const requestSportsman = sortDeep(this.request.sportsman || []);
            const dtoSportsman = sortDeep(dto.request.sportsman || []);
            for (let i = 0; i < dtoSportsman.length; i += 1) {
                if (!requestSportsman[i]) {
                    diff.sportsman.push(this.sortObj(dtoSportsman[i], sportsmanKeysOrder));
                    continue;
                }
                const sportsmanDiff = deepEqualInAnyOrder(requestSportsman[i], dtoSportsman[i]);
                if (Object.keys(sportsmanDiff).length > 0) {
                    diff.sportsman.push(this.sortObj(sportsmanDiff, sportsmanKeysOrder));
                }
            }
        }
        return this.sortObj(diff, baseOrder);
    }
    static fromDTO(data) {
        return new UserUpdateRequestDTO(data.request, data.commentary, data.documents);
    }
    static emptyRaw() {
        return {
            main: {
                name: null,
                surname: null,
                patronymic: null,
                birthdate: null,
                gender: null,
                phone: null,
                avatar: null,
                address: {
                    regionId: null,
                    cityId: null,
                },
                inNationalTeamSinceYear: null,
            },
            passport: {
                serial: null,
                number: null,
                authorityCode: null,
                authority: null,
                birthPlace: null,
                issueDate: null,
            },
            equip: {
                size: null,
                height: null,
                shoeSize: null,
            },
            employment: {
                education: null,
                workPlace: null,
                educationPlace: null,
            },
            living: {
                address: null,
                index: null,
            },
            social: {
                medicialInsurance: null,
                TIN: null,
                INIPA: null,
            },
            aboard: {
                hasAboardPassport: false,
                surname: null,
                name: null,
                patronymic: null,
                number: null,
                authority: null,
                issueDate: null,
                expirationDate: null,
                visitedCountries: [],
                hasUsaVisa: false,
                hasBiometrics: false,
                biometricsIssueDate: null,
            },
            familyStatus: {
                partnershipType: null,
                partnerSNP: null,
                partnerMaidenName: null,
                partnerCitizenship: null,
                partnerBirthdate: null,
            },
            parents: {
                motherSNP: null,
                motherBirthdate: null,
                fatherSNP: null,
                fatherBirthdate: null,
            },
            sportsman: [],
            coach: {
                category: null,
            },
            judge: {
                category: null,
                order: null,
                orderDate: null,
            },
        };
    }
    static createMainFromInput(input) {
        return {
            name: new NullablePrimitive(input.name).get(),
            surname: new NullablePrimitive(input.surname).get(),
            patronymic: new NullablePrimitive(input.patronymic).get(),
            birthdate: new NullablePrimitive(input.birthdate, (value) => moment(value).format("YYYY-MM-DD")).get(),
            gender: new NullablePrimitive(input.gender).get(),
            phone: new NullablePrimitive(input.phone).get(),
            avatar: new NullablePrimitive(input.avatar).get(),
            address: {
                regionId: new NullablePrimitive(input.address.regionId, (value) => parseInt(String(value), 10)).get(),
                cityId: new NullablePrimitive(input.address.cityId, (value) => parseInt(String(value), 10)).get(),
            },
            inNationalTeamSinceYear: new NullablePrimitive(input.inNationalTeamSinceYear, (value) => parseInt(String(value), 10)).get(),
        };
    }
    static createPassportFromInput(input) {
        return {
            serial: new NullablePrimitive(input.serial).get(),
            number: new NullablePrimitive(input.number).get(),
            authorityCode: new NullablePrimitive(input.authorityCode).get(),
            authority: new NullablePrimitive(input.authority).get(),
            birthPlace: new NullablePrimitive(input.birthPlace).get(),
            issueDate: new NullablePrimitive(input.issueDate, (value) => moment(value).format("YYYY-MM-DD")).get(),
        };
    }
    static createEquipFromInput(input) {
        return {
            size: new NullablePrimitive(input.size).get(),
            height: new NullablePrimitive(input.height).get(),
            shoeSize: new NullablePrimitive(input.shoeSize).get(),
        };
    }
    static createEmploymentFromInput(input) {
        return {
            education: new NullablePrimitive(input.education).get(),
            workPlace: new NullablePrimitive(input.workPlace).get(),
            educationPlace: new NullablePrimitive(input.educationPlace).get(),
        };
    }
    static createLivingFromInput(input) {
        return {
            address: new NullablePrimitive(input.address).get(),
            index: new NullablePrimitive(input.index, (value) => parseInt(String(value), 10)).get(),
        };
    }
    static createSocialFromInput(input) {
        return {
            medicialInsurance: new NullablePrimitive(input.medicialInsurance).get(),
            TIN: new NullablePrimitive(input.TIN).get(),
            INIPA: new NullablePrimitive(input.INIPA).get(),
        };
    }
    static createAboardFromInput(input) {
        return {
            hasAboardPassport: !!input.hasAboardPassport,
            surname: new NullablePrimitive(input.surname).get(),
            name: new NullablePrimitive(input.name).get(),
            patronymic: new NullablePrimitive(input.patronymic).get(),
            number: new NullablePrimitive(input.number).get(),
            authority: new NullablePrimitive(input.authority).get(),
            issueDate: new NullablePrimitive(input.issueDate, (value) => moment(value).format("YYYY-MM-DD")).get(),
            expirationDate: new NullablePrimitive(input.expirationDate, (value) => moment(value).format("YYYY-MM-DD")).get(),
            visitedCountries: Array.isArray(input.visitedCountries) ? input.visitedCountries : [],
            hasUsaVisa: !!input.hasUsaVisa,
            hasBiometrics: !!input.hasBiometrics,
            biometricsIssueDate: new NullablePrimitive(input.biometricsIssueDate, (value) => moment(value).format("YYYY-MM-DD")).get(),
        };
    }
    static createFamilyStatusFromInput(input) {
        return {
            partnershipType: new NullablePrimitive(input.partnershipType).get(),
            partnerSNP: new NullablePrimitive(input.partnerSNP).get(),
            partnerMaidenName: new NullablePrimitive(input.partnerMaidenName).get(),
            partnerCitizenship: new NullablePrimitive(input.partnerCitizenship).get(),
            partnerBirthdate: new NullablePrimitive(input.partnerBirthdate, (value) => moment(value).format("YYYY-MM-DD")).get(),
        };
    }
    static createParentsFromInput(input) {
        return {
            motherSNP: new NullablePrimitive(input.motherSNP).get(),
            motherBirthdate: new NullablePrimitive(input.motherBirthdate, (value) => moment(value).format("YYYY-MM-DD")).get(),
            fatherSNP: new NullablePrimitive(input.fatherSNP).get(),
            fatherBirthdate: new NullablePrimitive(input.fatherBirthdate, (value) => moment(value).format("YYYY-MM-DD")).get(),
        };
    }
    static createSportsmanFromInput(items) {
        return items.map((input) => ({
            disciplineGroup: new NullablePrimitive(input.disciplineGroup).get(),
            discipline: new NullablePrimitive(input.discipline).get(),
            firstCoachId: new NullablePrimitive(input.firstCoachId).get(),
            firstCoachSNP: new NullablePrimitive(input.firstCoachSNP).get(),
            personalCoachId: new NullablePrimitive(input.personalCoachId).get(),
            personalCoachSNP: new NullablePrimitive(input.personalCoachSNP).get(),
            startYear: new NullablePrimitive(input.startYear, (value) => parseInt(String(value), 10)).get(),
        }));
    }
    static createCoachFromInput(input) {
        return {
            category: new NullablePrimitive(input.category).get(),
        };
    }
    static createJudgeFromInput(input) {
        return {
            category: new NullablePrimitive(input.category).get(),
            order: new NullablePrimitive(input.order).get(),
            orderDate: new NullablePrimitive(input.orderDate, (value) => moment(value).format("YYYY-MM-DD")).get(),
        };
    }
    static createFromUser(user) {
        const hasItem = (item) => user.profile && user.profile[item];
        return new UserUpdateRequestDTO({
            main: UserUpdateRequestDTO.createMainFromInput({
                name: user.name,
                surname: user.surname,
                patronymic: user.patronymic,
                birthdate: user.birthdate,
                gender: user.gender,
                phone: user.phone,
                avatar: user.avatar,
                address: {
                    regionId: user.address ? user.address.regionId : null,
                    cityId: user.address ? user.address.cityId : null,
                },
                inNationalTeamSinceYear: user.profile ? user.profile.inNationalTeamSinceYear : null,
            }),
            passport: hasItem("passport")
                ? UserUpdateRequestDTO.createPassportFromInput(user.profile.passport)
                : UserUpdateRequestDTO.emptyRaw().passport,
            equip: hasItem("equip")
                ? UserUpdateRequestDTO.createEquipFromInput(user.profile.equip)
                : UserUpdateRequestDTO.emptyRaw().equip,
            employment: hasItem("employment")
                ? UserUpdateRequestDTO.createEmploymentFromInput(user.profile.employment)
                : UserUpdateRequestDTO.emptyRaw().employment,
            living: hasItem("living")
                ? UserUpdateRequestDTO.createLivingFromInput(user.profile.living)
                : UserUpdateRequestDTO.emptyRaw().living,
            social: hasItem("social")
                ? UserUpdateRequestDTO.createSocialFromInput(user.profile.social)
                : UserUpdateRequestDTO.emptyRaw().social,
            aboard: hasItem("aboard")
                ? UserUpdateRequestDTO.createAboardFromInput(user.profile.aboard)
                : UserUpdateRequestDTO.emptyRaw().aboard,
            familyStatus: hasItem("familyStatus")
                ? UserUpdateRequestDTO.createFamilyStatusFromInput(user.profile.familyStatus)
                : UserUpdateRequestDTO.emptyRaw().familyStatus,
            parents: hasItem("visaProfile")
                ? UserUpdateRequestDTO.createParentsFromInput(user.profile.visaProfile)
                : UserUpdateRequestDTO.emptyRaw().parents,
            sportsman: hasItem("sportsman")
                ? UserUpdateRequestDTO.createSportsmanFromInput(user.profile.sportsman)
                : UserUpdateRequestDTO.emptyRaw().sportsman,
            coach: hasItem("coach")
                ? UserUpdateRequestDTO.createCoachFromInput(user.profile.coach)
                : UserUpdateRequestDTO.emptyRaw().coach,
            judge: hasItem("judge")
                ? UserUpdateRequestDTO.createJudgeFromInput(user.profile.judge)
                : UserUpdateRequestDTO.emptyRaw().judge,
        }, null, []);
    }
    static fillDifferenceByUser(diff, user) {
        const userDto = UserUpdateRequestDTO.createFromUser(user);
        const userRequestByDiff = {};
        for (const updateRequestItem in userDto.request) {
            if (!Object.prototype.hasOwnProperty.call(diff, updateRequestItem))
                continue;
            const updateRequestKey = updateRequestItem;
            if (updateRequestKey === "sportsman") {
                userRequestByDiff[updateRequestKey] = [];
                for (const sportsmanItem of diff[updateRequestKey]) {
                    const obj = {};
                    for (const prop in sportsmanItem) {
                        obj[prop] = userDto.request.sportsman[prop];
                    }
                    userRequestByDiff[updateRequestKey].push(obj);
                }
            }
            else {
                userRequestByDiff[updateRequestKey] = {};
                for (const prop in diff[updateRequestKey]) {
                    ;
                    userRequestByDiff[updateRequestKey][prop] = userDto.request[updateRequestKey][prop];
                }
            }
        }
        return userRequestByDiff;
    }
}
