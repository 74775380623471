import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import moment from "moment-timezone";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import UserCard from "./UserCard.vue";
import UserUpdateRequestCard from "./UserUpdateRequestCard.vue";
let UsersTable = class UsersTable extends Vue {
    constructor() {
        super(...arguments);
        this.clearSelected = false;
        this.addMemberDialog = false;
        this.selected = [];
        this.search = "";
        this.loading = true;
        this.page = 1;
        this.pagesCount = 0;
    }
    get users() {
        if (this.customUsers !== null)
            return this.customUsers;
        return this.$store.getters[UsersModule.types.getters.USERS_BY_CATEGORIES];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get cHeaders() {
        if (this.shortHeaders) {
            return [
                {
                    text: "Фамилия",
                    sortable: true,
                    value: "surname",
                },
                {
                    text: "Имя",
                    sortable: true,
                    value: "name",
                },
                {
                    text: "Отчество",
                    sortable: true,
                    value: "patronymic",
                    align: "center",
                },
            ];
        }
        else {
            return this.headers;
        }
    }
    get isLK() {
        return this.category === "lk";
    }
    onSelect() {
        this.$emit("on-select", this.selected);
    }
    onClearSelectedChange() {
        this.selected = [];
    }
    formatDate(date) {
        return moment(date).tz("Europe/Moscow").format("YYYY-MM-DD");
    }
    async mounted() {
        if (this.customUsers !== null) {
            this.loading = false;
            return;
        }
        await this.$store.dispatch(AdminModule.types.actions.WAIT_FOR_ADMIN_LOADING);
        const usersQuery = {};
        if (this.admin !== null && !this.admin.isSuperAuth) {
            usersQuery.regionId = this.admin.regionId;
        }
        await this.$store.dispatch(UsersModule.types.actions.GET_USERS, usersQuery);
        this.loading = false;
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], UsersTable.prototype, "clearSelected", void 0);
__decorate([
    Prop({ type: String, default: "active" })
], UsersTable.prototype, "category", void 0);
__decorate([
    Prop({ type: Object, default: null })
], UsersTable.prototype, "customUsers", void 0);
__decorate([
    Prop({ type: String, default: "expiryDate" })
], UsersTable.prototype, "sortBy", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UsersTable.prototype, "showUserVersion", void 0);
__decorate([
    Prop({ type: Number, default: 25 })
], UsersTable.prototype, "itemsPerPage", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], UsersTable.prototype, "shortHeaders", void 0);
__decorate([
    Prop({
        type: Array,
        default: () => [
            {
                text: "Фамилия",
                sortable: true,
                value: "surname",
            },
            {
                text: "Имя",
                sortable: true,
                value: "name",
            },
            {
                text: "Отчество",
                sortable: true,
                value: "patronymic",
                align: "center",
            },
            {
                text: "Дата рождения",
                sortable: true,
                value: "birthdate",
            },
            {
                text: "№ билета",
                sortable: true,
                value: "idRfsl",
            },
            {
                text: "Срок действия",
                sortable: true,
                value: "expiryDate",
            },
            {
                text: "Дата вступления",
                sortable: true,
                value: "entryDate",
            },
            { text: "", value: "data-table-expand" },
        ],
    })
], UsersTable.prototype, "headers", void 0);
__decorate([
    Watch("selected")
], UsersTable.prototype, "onSelect", null);
__decorate([
    Watch("clearSelected")
], UsersTable.prototype, "onClearSelectedChange", null);
UsersTable = __decorate([
    Component({
        components: {
            UserCard,
            UserUpdateRequestCard,
        },
    })
], UsersTable);
export default UsersTable;
