export class PhoneMask {
    static getAvailableCodes() {
        return ["ru", "az", "am", "by", "kz", "md", "tj", "uz", "ua"];
    }
    static getPlaceholderByCountry(country) {
        const table = {
            am: "077 123456",
            az: "040 123 45 67",
            by: "029 491-19-11",
            kz: "(771) 000 9998",
            md: "0621 12 345",
            ru: "(912) 345-67-89",
            tj: "917 12 3456",
            ua: "050 123 4567",
            uz: "91 234 56 78"
        };
        return table[country];
    }
    static getMaskByPlaceholder(dialCode, placeholder) {
        return `${dialCode} ${placeholder.replace(/[0-9]/g, "#")}`.trim();
    }
}
