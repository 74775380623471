import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { IMAGE_SERVER_URL } from "@/store/shared/ApiRequest";
import moment from "moment-timezone";
import AdminModule from "@/store/module/admin/AdminModule";
import UserBasicInfo from "../dialogs/User/UserBasicInfo.vue";
import UserProfileViewDialog from "../dialogs/User/UserProfileView.vue";
let UserCard = class UserCard extends Vue {
    constructor() {
        super(...arguments);
        this.updateMember = {
            dialog: false,
        };
        this.updateProfile = {
            dialog: false,
        };
        this.showProfile = {
            dialog: false,
        };
        this.profile = {
            main: [
                { title: "Фамилия:", value: this.user.surname },
                { title: "Имя:", value: this.user.name },
                { title: "Отчество:", value: this.user.patronymic },
                {
                    title: "День рождения:",
                    value: moment(this.user.birthdate).tz("Europe/Moscow").format("DD.MM.YYYY"),
                },
                { title: "Номер билета:", value: this.user.idRfsl },
                {
                    title: "Срок действия билета:",
                    value: moment(this.user.expiryDate).tz("Europe/Moscow").format("DD.MM.YYYY"),
                },
                {
                    title: "Дата вступления:",
                    value: moment(this.user.entryDate).tz("Europe/Moscow").format("DD.MM.YYYY"),
                },
                {
                    title: "Спортивное звание/Разряд:",
                    value: this.user.sportsCategory === null || (this.user.sportsCategory && !this.user.sportsCategory.sportsCategory)
                        ? "Не указано"
                        : this.user.sportsCategory.sportsCategory.category,
                },
            ],
            contacts: [
                {
                    title: "Регион:",
                    value: this.user.address.region ? this.user.address.region.name : "Не указан",
                },
                {
                    title: "Город:",
                    value: this.user.address.city ? this.user.address.city.name : "Не указан",
                },
                { title: "Телефон:", value: this.user.phone || "Не указан" },
                { title: "E-mail:", value: this.user.email || "Не указан" },
            ],
        };
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    get admin() {
        return this.$store.getters[AdminModule.types.getters.ADMIN];
    }
    get isAdmin() {
        return !!this.admin;
    }
    get canEdit() {
        if (this.isSuperAdmin)
            return true;
        if (this.isAdmin && this.user.address.regionId === this.admin.regionId)
            return true;
        return false;
    }
    get avatar() {
        if (this.user && this.user.avatar) {
            return `${IMAGE_SERVER_URL}/public/${this.user.avatar}`;
        }
        else {
            return `${IMAGE_SERVER_URL}/public/user-placeholder-${this.user.gender}.jpg`;
        }
    }
};
__decorate([
    Prop({ type: Object })
], UserCard.prototype, "user", void 0);
UserCard = __decorate([
    Component({
        components: {
            UserBasicInfo,
            // UserProfileModify,
            UserProfileViewDialog,
        },
    })
], UserCard);
export default UserCard;
