import { __decorate } from "tslib";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment-timezone";
import UsersModule from "@/store/module/admin/UsersModule";
import AdminModule from "@/store/module/admin/AdminModule";
import ApproveDialog from "../../shared/Approve.vue";
let CategoriesButtons = class CategoriesButtons extends Vue {
    constructor() {
        super(...arguments);
        this.categories = {
            active: [
                {
                    text: "Продлить членство",
                    action: "extendMembership",
                    css: "blue darken-1",
                    showOnlyForSuperAdmin: false,
                },
                {
                    text: "Приостановить членство",
                    action: "suspend",
                    css: "red",
                    showOnlyForSuperAdmin: false,
                },
                {
                    text: "Почетное членство",
                    action: "honorary",
                    css: "cyan",
                    showOnlyForSuperAdmin: true,
                },
                {
                    text: "Добавить в сборную",
                    action: "addToNationalTeam",
                    css: "cyan darken-2",
                    showOnlyForSuperAdmin: true,
                },
            ],
            inactive: [
                {
                    text: "Продлить членство",
                    action: "extendMembership",
                    css: "blue darken-1",
                    showOnlyForSuperAdmin: false,
                },
                {
                    text: "Приостановить членство",
                    action: "suspend",
                    css: "red",
                    showOnlyForSuperAdmin: false,
                },
                {
                    text: "Добавить в сборную",
                    action: "addToNationalTeam",
                    css: "cyan darken-2",
                    showOnlyForSuperAdmin: true,
                },
            ],
            suspended: [
                {
                    text: "Восстановить членство",
                    action: "desuspend",
                    css: "blue darken-1",
                    showOnlyForSuperAdmin: false,
                },
            ],
            junior: [
                {
                    text: "Добавить в сборную",
                    action: "addToNationalTeam",
                    css: "cyan darken-2",
                    showOnlyForSuperAdmin: true,
                },
            ],
            honorary: [
                {
                    text: "Приостановить почетное членство",
                    action: "dehonorary",
                    css: "red text-caption font-weight-thin",
                    showOnlyForSuperAdmin: true,
                },
                {
                    text: "Добавить в сборную",
                    action: "addToNationalTeam",
                    css: "cyan darken-2",
                    showOnlyForSuperAdmin: true,
                },
            ],
            premoderated: [
                {
                    text: "Добавить",
                    action: "depremoderate",
                    css: "blue darken-1",
                    showOnlyForSuperAdmin: true,
                },
                {
                    text: "Удалить",
                    action: "delete",
                    css: "red",
                    showOnlyForSuperAdmin: true,
                },
            ],
            lk: [],
            nationalTeam: [
                {
                    text: "Исключить из сборной",
                    action: "removeFromNationalTeam",
                    css: "red",
                    showOnlyForSuperAdmin: true,
                },
            ],
        };
        this.actions = {
            extendMembership: {
                actionType: "update",
                state: false,
                success: {
                    title: "Продление членства у выбранных пользователей прошло успешно!",
                },
                error: {
                    title: "Ошибка продления членства!",
                    computedText: (surname, name, patronymic) => `У пользователя ${surname} ${name} ${patronymic} уже продленное членство!`,
                },
                handler: (user) => {
                    const currentYear = moment().format("YYYY");
                    user.expiryDate = moment(`${currentYear}-12-31 23:59:59`).tz("Europe/Moscow").format("YYYY-MM-DD HH:mm:ss");
                    return "expiryDate";
                },
            },
            suspend: {
                actionType: "update",
                state: false,
                success: {
                    title: "Членство выбранных пользователей успешно приостановленно!",
                },
                error: {
                    title: "Ошибка приостановки членства!",
                },
                handler: (user) => {
                    user.isStopped = true;
                    return "isStopped";
                },
            },
            honorary: {
                actionType: "update",
                state: false,
                success: {
                    title: "Почетное членство успешно назначено!",
                },
                error: {
                    title: "Ошибка назначения почетного членства!",
                },
                handler: (user) => {
                    user.isHonorary = true;
                    return "isHonorary";
                },
            },
            desuspend: {
                actionType: "update",
                state: false,
                success: {
                    title: "Членство выбранных пользователей успешно возобновлено!",
                },
                error: {
                    title: "Ошибка возобновления членства!",
                },
                handler: (user) => {
                    user.isStopped = false;
                    return "isStopped";
                },
            },
            dehonorary: {
                actionType: "update",
                state: false,
                success: {
                    title: "Почетное членство выбранных пользователей успешно приостановлено!",
                },
                error: {
                    title: "Ошибка приостановления почетного членства!",
                },
                handler: (user) => {
                    user.isHonorary = false;
                    return "isHonorary";
                },
            },
            depremoderate: {
                actionType: "update",
                state: false,
                success: {
                    title: "Выбранные пользователи успешно добавлены в систему!",
                },
                error: {
                    title: "Ошибка добавления выбранных пользователей в систему!",
                },
                handler: (user) => {
                    user.isPremoderated = false;
                    return "isPremoderated";
                },
            },
            delete: {
                actionType: "delete",
                state: false,
                success: {
                    title: "Выбранные пользователи успешно удалены!",
                },
                error: {
                    title: "Ошибка удаления выбранных пользователей!",
                },
                handler: () => "id",
            },
            addToNationalTeam: {
                actionType: "update",
                state: false,
                success: {
                    title: "Выбранные пользователи успешно добавлены в сборную!",
                },
                error: {
                    title: "Ошибка добавления выбранных пользователей в сборную!",
                },
                handler: (user) => {
                    user.isNationalTeamMember = true;
                    return "isNationalTeamMember";
                },
            },
            removeFromNationalTeam: {
                actionType: "update",
                state: false,
                success: {
                    title: "Выбранные пользователи успешно исключены из сборной!",
                },
                error: {
                    title: "Ошибка исключения выбранных пользователей из сборной!",
                },
                handler: (user) => {
                    user.isNationalTeamMember = false;
                    return "isNationalTeamMember";
                },
            },
        };
    }
    get isSuperAdmin() {
        return this.$store.getters[AdminModule.types.getters.IS_SUPER_ADMIN];
    }
    async usersAction(action) {
        console.log(action);
        let hasSuccessResult = false;
        for (let user of this.selectedUsers) {
            console.log(this.actions[action]);
            const field = this.actions[action].handler(user);
            if (field === false)
                continue;
            if (this.actions[action].actionType === "update") {
                await this.$store.dispatch(UsersModule.types.actions.UPDATE_USER, {
                    id: user.id,
                    user: { [field]: user[field] },
                });
            }
            else {
                await this.$store.dispatch(UsersModule.types.actions.DELETE_USER, { id: user.id });
            }
            hasSuccessResult = true;
        }
        if (!hasSuccessResult)
            return;
        this.$notify({
            type: "success",
            ...this.actions[action].success,
        });
        this.$emit("clear-selected");
    }
};
__decorate([
    Prop({ type: Array, required: true })
], CategoriesButtons.prototype, "selectedUsers", void 0);
__decorate([
    Prop({ type: String, required: true })
], CategoriesButtons.prototype, "category", void 0);
CategoriesButtons = __decorate([
    Component({
        components: {
            ApproveDialog,
        },
    })
], CategoriesButtons);
export default CategoriesButtons;
